<template>
  <div class="box">
    <div class="title">
      <p @click="back">
        <van-icon class="icon" name="arrow-left" />
      </p>

      <header>竞猜押宝</header>
    </div>
    <div style="height: 40px"></div>
    <!-- user信息 -->
    <Act />

    <div class="main">
      <!-- 投注选择 -->
      <div class="card">
        <div class="date">
          <div class="date_left">
            <p>{{ dataTime }}</p>
            <img
              v-if="dataTime == time"
              class="today"
              height="20px"
              src="@/assets/images/today.png"
              alt=""
            />
          </div>
          <p>奖池总竞猜币：{{ data ? data[0].integrate + data[1].integrate : "" }}竞猜币</p>
        </div>
        <div class="main_sec">
          <div class="VS">
            <div class="img_wid_h">
              <p class="vsImg">
                <img width="48px" src="@/assets/seal/buleY.png" alt="" />
              </p>
              <p style="margin: 0; padding: 0">1、{{ data ? data[0].content : "" }}</p>
            </div>
            <p class="vs_left">VS</p>
            <div class="img_wid_h">
              <p class="vsImg">
                <img width="48px" src="@/assets/seal/redY.png" alt="" />
              </p>
              <p style="margin: 0; padding: 0">2、{{ data ? data[1].content : "" }}</p>
            </div>
          </div>
          <p
            :style="
              subject_name.length > 10
                ? 'word-wrap: break-word;text-align:left;word-break:break-all'
                : 'word-wrap: break-word;'
            "
          >
            题目:{{ subject_name }}
          </p>
          <div class="contrast">
            <div
              class="max_min"
              :style="
                data
                  ? `width: ${
                      (data[0].integrate / (data[0].integrate + data[1].integrate)) * 100
                    }%`
                  : 'width:50%'
              "
            >
              <div class="betLeft">
                <p>{{ data ? data[0].integrate : "" }}竞猜币</p>
              </div>
            </div>
            <div class="center"></div>
            <div
              class="max_min"
              :style="
                data
                  ? `width: ${
                      (data[1].integrate / (data[0].integrate + data[1].integrate)) * 100
                    }%`
                  : 'width:50%'
              "
            >
              <div class="betRight">
                <p>{{ data ? data[1].integrate : "" }}竞猜币</p>
              </div>
            </div>
          </div>
          <div class="contrast">
            <p
              v-for="(item, index) in data"
              :key="item.id"
              class="downBet"
              :style="index == 0 ? 'margin-left:-30px' : 'margin-right:-30px'"
            >
              有{{ item.user_count }}人下注，赔率{{ item.the_odds }}
            </p>
          </div>
          <div class="contrast" style="margin-top: 15px">
            <button
              @click="status == 1 ? showPopup(data[0].id) : ''"
              :style="status == 1 ? '' : 'background:#DAE0ED'"
            >
              投注
            </button>
            <button
              @click="status == 1 ? showPopup(data[1].id) : ''"
              :style="status == 1 ? '' : 'background:#DAE0ED'"
            >
              投注
            </button>
          </div>
        </div>
      </div>
      <!-- 我的投注 -->
      <div class="record">
        <div class="myBet">
          我的投注
          <van-popover
            v-model="showDialog"
            placement="bottom-start"
            :offset="[-64, 0]"
            ref="popover"
          >
            <img
              src="@/assets/images/help.png"
              slot="reference"
              @click="showDialog = true"
            />
            <div style="padding: 12px; font-size: 12px; color: #838c8f; width: 288px">
              <div>胜利方在获得竞猜币时，需要按照利润的比例交税</div>
              <div>利润计算公式=胜利获得的竞猜币-投入的竞猜币</div>
              <div>扣除金额=对应比例*利润</div>
              <table class="help-table">
                <tr>
                  <th>条件（利润值a）</th>
                  <th>比例</th>
                </tr>
                <tr>
                  <td>小于等于1000</td>
                  <td>不扣除</td>
                </tr>
                <tr>
                  <td>1000&lt;a&lt;=4000</td>
                  <td>5%</td>
                </tr>
                <tr>
                  <td>4000&lt;a&lt;=8000</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>a>8000</td>
                  <td>15%</td>
                </tr>
              </table>
            </div>
          </van-popover>
        </div>
        <div v-if="winLog[0]">
          <div v-for="(item, key) in winLog" :key="key + 'win-log'">
            <div class="win-log-item" v-if="key !== 'option_name'">
              <div style="display: flex; align-items: center; height: 20px">
                <img src="@/assets/images/SHENG-LI.png" v-if="item.is_win === 2" />
                <img src="@/assets/images/SHI-BAI.png" v-if="item.is_win === 1" />
              </div>
              <div style="flex: 1 1; text-align: left">
                {{ winLog.option_name }} 获胜，竞猜{{
                  item.is_win === 2 ? "成功" : "失败"
                }}
                <span v-if="item.is_win === 1">
                  ，
                  <span style="color: #47a3e6"> -{{ item.integrate }} </span>
                  竞猜币
                </span>
              </div>
              <div style="white-space: nowrap; margin-left: 8px">
                <span :style="'color:' + (item.is_win === 1 ? '#47A3E6' : '#E96346')">
                  {{ item.is_win === 1 ? "-" : "+" }}
                  {{ item.is_win === 1 ? item.profit : item.integrate }}
                </span>
                竞猜币
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="userBet.length > 0">
          <div class="bet_main" v-for="item in userBet" :key="item.id">
            <p>投注了「{{ item.subject_option.content }}」{{ item.integrate }}竞猜币</p>
            <p
              v-if="item.status == 1"
              :style="
                status == 1
                  ? 'color: #E96346;text-align:right'
                  : 'color:#DAE0ED;text-align:right'
              "
              @click="status == 1 ? cancelBet(item) : ''"
            >
              撤回
            </p>
            <p v-else style="color: #999; white-space: nowrap; text-align: right">
              已撤回
            </p>
          </div>
        </div>
        <div v-else class="betNull">暂无记录</div>
      </div>
      <!-- 投注记录 -->

      <div class="record">
        <div class="myBet2">
          <div>投注记录</div>
          <!-- <p style="color: #999; font-size: 16px">下载</p> -->
        </div>
        <div class="bet_name" v-if="allBet.length > 0">
          <p style="width: 280px" v-for="item in allBet" :key="item.id">
            {{ item.user.name }} 支持了「{{ item.subject_option.content
            }}」{{ item.integrate }} 竞猜币
          </p>
        </div>
        <div v-else class="betNull">暂无记录</div>
      </div>
    </div>
    <van-popup v-model="show" position="bottom" class="popup">
      <div class="popup_head">
        <div style="width: 80px">
          <span style="font-size: 24px" @click="hidePopup">×</span>
        </div>
        <span style="font-size: 16px">确认投注</span>
        <button v-if="double" class="really" @click="creatBet">确认</button>
        <button v-else class="really">确认</button>
      </div>
      <div class="betM">
        <p
          v-for="item in money"
          :key="item"
          class="money"
          @click="
            recMoney = item;
            inputMoney = undefined;
          "
          :id="item == recMoney ? 'recMoney' : ''"
        >
          {{ item }}
        </p>
      </div>
      <div class="popup_val">
        <span style="color: #e96346"
          >余额 :
          {{
            $store.state.userInfo ? $store.state.userInfo.additional.integrate : ""
          }}</span
        >
        <div class="popup_foot">
          <span style="font-size: 14px">输入投注 </span>
          <input
            style="color: #fdc261; height: 30px"
            type="number"
            v-model="inputMoney"
            placeholder="输入任意金额"
            @keyup="maxVal"
            oninput="value=value.replace(/[^\d]/g,'')"
          />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Icon, Popup, Notify, Popover, Dialog } from "vant";
import Act from "../../components/outLogin/index.vue";

Vue.use(Popup);
Vue.use(Icon);
Vue.use(Popover);
export default {
  components: {
    Act,
  },
  data() {
    return {
      show: false,
      money: [100, 200, 500],
      recMoney: 100,
      inputMoney: false,
      data: false,
      time: "",
      dataTime: "",
      betId: "",
      userBet: false,
      allBet: false,
      subject_name: false,
      url: "",
      double: true,
      status: 1,
      showDialog: false,
      winLog: false,
    };
  },
  created() {
    var _this = this;
    let mm = new Date().getMonth() + 1;
    let dd = new Date().getDate();
    _this.gettime = mm + "-" + dd;
    this.time = _this.gettime;
    this.getUser();
    this.getSub(this.$route.query.id);
    this.getUserSubjectLog(this.$route.query.id);
    this.getSubjectLog(this.$route.query.id);
    this.getWinLog(this.$route.query.id);
    console.log(this.$store);
  },
  mounted() {
    document.addEventListener("click", this.onClickOutsideEventHandle);
  },
  destroyed() {
    let input = document.getElementsByTagName("input");
    console.log("输入框集合");
    console.log(input);
    for (const item of input) {
      item.blur();
    }
    document.removeEventListener("click", this.onClickOutsideEventHandle);
  },
  methods: {
    //返回竞猜页面
    back() {
      // this.$router.go(-1);
      this.$router.push({
        name: "guessResult",
      });
    },

    //设置最大投注不超过本身拥有
    maxVal() {
      if (Number(this.inputMoney) > this.$store.state.userInfo.additional.integrate) {
        this.inputMoney = this.$store.state.userInfo.additional.integrate;
      }
    },
    //投注操作
    async creatBet() {
      this.double = false;
      var integrate = 0;
      if (this.inputMoney) {
        integrate = this.inputMoney;
      } else {
        integrate = this.recMoney;
      }
      const res = await this.$api.Subject.createBet({
        subject_option_id: this.betId,
        integrate: integrate,
      });
      if (res.errorCode == 1000) {
        Notify({ type: "success", message: res.errorMessage });
        this.getSub(this.$route.query.id);
        this.getSubjectLog(this.$route.query.id);
        this.getUserSubjectLog(this.$route.query.id);
        this.getUser();
      } else {
        Notify({ type: "danger", message: res.errorMessage });
      }
      this.show = false;
      setTimeout(() => {
        this.double = true;
      }, 500);
    },

    //选项投注记录
    async getSubjectLog(id) {
      const res = await this.$api.Subject.getSubjectLog({ subject_id: id });
      if (res.errorCode == 1000) {
        this.allBet = res.data;
      } else {
        Notify({ type: "danger", message: res.errorMessage });
      }
    },

    async getWinLog(id) {
      const res = await this.$api.Record.getSubjectWinLog({ subject_id: id });
      if (res.errorCode == 1000) {
        this.winLog = res.data;
      } else {
        Notify({ type: "danger", message: res.errorMessage });
      }
    },

    //获取用户选项投注记录
    async getUserSubjectLog(id) {
      const res = await this.$api.Subject.getUserSubjectLog({ subject_id: id });
      if (res.errorCode == 1000) {
        this.userBet = res.data;
      } else {
        Notify({ type: "danger", message: res.errorMessage });
      }
    },
    showPopup(id) {
      this.show = true;
      this.betId = id;
    },
    hidePopup() {
      this.show = false;
    },
    async getUser() {
      const res = await this.$api.UserInfo.getUser();
      if (res.errorCode == 1000) {
        this.$store.commit("changeLoginState", res.data);
      }
    },
    //撤销投注
    async cancelBet(data) {
      Dialog.confirm({
        message: `确定撤回该项投注？`,
      })
        .then(async () => {
          const res = await this.$api.Subject.cancelBet({ id: data.id });
          if (res.errorCode == 1000) {
            Notify({ type: "success", message: res.errorMessage });
            data.status = 2;
            this.getUser();
            this.getSub(this.$route.query.id);
            this.getSubjectLog(this.$route.query.id);
          } else {
            Notify({ type: "danger", message: res.errorMessage });
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    //获取页面数据
    async getSub(id) {
      const res = await this.$api.Subject.getSubjectOptionList({
        subject_id: id,
      });
      if (res.errorCode == 1000) {
        this.data = res.data.list;
        this.status = res.data.subject.status;
        this.subject_name = res.data.subject.name;
        this.dataTime = res.data.subject.created_at.substring(5, 10);
      }
    },
    onClickOutsideEventHandle(event) {
      if (this.showDialog) {
        console.log("=====");
      }
      const popoverWraperEl = this.$refs?.popover?.$el;
      if (popoverWraperEl && popoverWraperEl.contains(event.target)) return;
      const popoverEl = this.$refs?.popover?.$refs?.popover?.$el;
      if (popoverEl && popoverEl.contains(event.target)) return;

      this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.icon {
  font-size: 18px;
  font-weight: bold;
  position: fixed;
  top: 12px;
  left: 10px;
  z-index: 50000;
}
.max_min {
  max-width: 70%;
  min-width: 30%;
  line-height: 14px;
}
.really {
  width: 80px;
  height: 26px;
  border-radius: 3px;
  color: white;
  background: #e96346;
  border: none;
  font-size: 14px;
}
.box {
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 16px;
  // background: #f4f9ff;

  // height: 100vh;
  // white-space: nowrap;
  // overflow-y: scroll;
  // overflow-x: hidden;
  box-sizing: border-box;
  padding-bottom: 20px;
  .surplus {
    height: 40px;
    width: 100%;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    box-sizing: border-box;
    .div {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid #ddd;
      background-repeat: no-repeat;
      background-size: cover;
      img {
        margin-right: 9px;
      }
    }
    span {
      color: #fdc261;
    }
  }
  .main {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
    margin-top: 30px;
    .card {
      width: 100%;
      background: white;
      padding-bottom: 20px;
      box-shadow: 0 0 5px 0 #ddd;
      .date {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding: 0 10px;
        box-sizing: border-box;
        align-items: center;
        .date_left {
          display: flex;
          align-items: center;
          .today {
            line-height: 20px;
            color: white;
            border-radius: 7px 0 7px 0;
            background: #79cdf4;
            margin-left: 5px;
            text-align: center;
            transform: scale(0.8);
          }
        }
      }
      .main_sec {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        .VS {
          display: flex;
          width: 100%;
          justify-content: center;
          margin-top: 30px;
          margin-bottom: 15px;
          .img_wid_h {
            width: 42%;
            p {
              // width: 100%;
              text-align: left;
              word-wrap: break-word;
              word-break: break-all;
              text-align: center;
            }
          }
          div {
            text-align: center;
          }
          .vsImg {
            width: 48px;
            height: 48px;
            border: 1px solid #666;
            border-radius: 50%;
            margin: auto;
            margin-bottom: 15px;
          }
          .vs_left {
            font-size: 22px;
            margin-top: 20px;
            margin-left: 15px;
            margin-right: 15px;
          }
        }
        .contrast {
          display: flex;
          width: 80%;
          margin: auto;
          justify-content: space-between;
          .center {
            width: 14px;
            margin: 0 2px;
            height: 14px;
            background: linear-gradient(
              135deg,
              #68a3e5 45%,
              white 45%,
              white 55%,
              #e36a62 55%,
              #e36a62 100%
            );
          }
          .betLeft {
            background: #68a3e5;
            border-radius: 15px 0 0 15px;
            height: 14px;
            margin: 0;
            margin-bottom: 3px;
            color: white;
            text-align: left;
            font-size: 12px;
            p {
              transform: scale(0.7);
              display: inline-block;
              margin: 0;
              margin-top: -3px;
            }
          }
          .betRight {
            background: #e36a62;
            border-radius: 0 15px 15px 0;
            height: 14px;
            margin: 0;
            margin-bottom: 3px;
            color: white;
            text-align: right;
            font-size: 12px;
            p {
              transform: scale(0.7);
              display: inline-block;
              margin: 0;
              margin-top: -3px;
            }
          }
          .downBet {
            font-size: 14px;
            color: #ccc;
            transform: scale(0.8);
            margin: 0;
            width: 100%;
          }
          button {
            width: 44%;
            height: 36px;
            color: white;
            background: #e96346;
            border: none;
            border-radius: 5px;
          }
        }
      }
    }
  }
  .popup {
    border-radius: 20px 20px 0 0;
    padding: 25px 15px;
    box-sizing: border-box;
    .popup_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #000;
    }
    .popup_val {
      display: flex;
      justify-content: space-between;
      height: 70px;
      align-items: center;
      font-size: 14px;
      .popup_foot {
        color: #999;
        display: flex;
        align-items: center;
      }
      input {
        width: 140px;
        height: 22px;
        text-align: right;
        margin-left: 10px;
        margin-top: 5px;
        border: #ddd 1px solid;
        box-sizing: border-box;
        vertical-align: middle;
        border-radius: 3px;
        padding-right: 5px;
      }
    }

    ::placeholder {
      color: #ddd;
    }
    .betM {
      height: 65px;
      // align-items: center;
      border-top: 1px solid rgb(238, 235, 235);
      border-bottom: 1px solid rgb(238, 235, 235);
      width: 109%;
      margin-left: -15px;
      display: flex;
      padding: 0 15px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    div {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .money {
        height: 30px;
        flex: 1 1;
        line-height: 30px;
        border: 1px solid #e96346;
        border-radius: 5px;
        margin: 0;
        margin-top: 15px;
        color: #e96346;
      }
      #recMoney {
        background: #e96346;
        color: white;
      }
    }
  }
  .title {
    width: 100%;
    text-align: left;
    // padding: 10px 0;
    background: #f8f8f8;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    min-height: 40px;
    box-sizing: border-box;
    top: 0;
    .icon {
      vertical-align: middle;
      font-weight: bold;
      font-size: 20px;
    }
    span {
      vertical-align: middle;
      margin-left: 15px;
      font-size: 16px;
    }
  }

  .record {
    width: 100%;
    min-height: 171px;
    max-height: 300px;
    box-shadow: 0 0 5px 0 #ddd;
    box-sizing: border-box;
    margin-top: 10px;
    background: white;
    padding-bottom: 5px;
    position: relative;
    // white-space: nowrap;
    overflow-y: scroll;
    overflow-x: hidden;
    .myBet {
      height: 40px;
      line-height: 40px;
      width: 100%;
      padding-left: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      display: flex;
      align-items: center;
      gap: 10px;
    }
    .bet_name {
      width: 100%;
      padding-left: 10px;
      color: #999;
    }
    .bet_main {
      display: flex;
      justify-content: space-between;
      p {
        margin: 0;
        margin-top: 8px;
        color: #999;
        padding: 0 10px;
        box-sizing: border-box;
        font-size: 14px;
        min-width: 65px;
      }
      :nth-child(1) {
        word-break: break-all;
        width: 270px;
      }
    }

    .win-log-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0px 10px;
      padding-top: 8px;
      color: #999;
      font-size: 14px;
      img {
        height: 16px;
        margin-right: 8px;
      }
    }
    .myBet2 {
      height: 40px;
      width: 100%;
      padding: 0 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #ddd;
      font-size: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
      }
    }
    p {
      text-align: left;
      margin: 0;
      font-size: 14px;
    }
    .betNull {
      margin: auto;
      color: #ccc;
      font-size: 20px;
      margin-top: 40px;
    }
  }
}

.help-table {
  border-collapse: collapse;
  border: solid 1px #dcdcdc;
  width: 100%;
  margin-top: 20px;
  line-height: 28px;
  td,
  tr,
  th {
    border: solid 1px #dcdcdc;
    text-align: left;
  }
  td {
    width: 50%;
    color: #0f2128;
  }
}

@media screen and (max-width: 320px) {
  .box {
    font-size: 14px;
  }
  .myBet,
  .myBet2 {
    font-size: 14px !important;
  }
  .title > span {
    font-size: 14px !important;
  }
}
</style>
